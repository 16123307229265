<template>
    <div>
        <div style="margin-bottom: 5px;">
            <el-input v-model="name" placeholder="请输入供应商名称" suffix-icon="el-icon-search" style="width: 200px;"
                      @keyup.enter.native="loadPost"></el-input>
            <!-- <el-select v-model="storage" placeholder="请选择仓库" style="margin-left: 5px;">
                <el-option
                        v-for="item in storageData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="goodstype" placeholder="请选择分类" style="margin-left: 5px;">
                <el-option
                        v-for="item in goodstypeData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select> -->
  
            <el-button type="primary" style="margin-left: 5px;" @click="loadPost">查询</el-button>
            <el-button type="success" @click="resetParam">重置</el-button>
  
            <el-button type="primary" style="margin-left: 5px;" @click="addNewPrice" v-if="user.roleId!=2">新增产品价格</el-button>
            <!-- <el-button type="primary" style="margin-left: 5px;" @click="inGoods" v-if="user.roleId!=2">入库</el-button>
            <el-button type="primary" style="margin-left: 5px;" @click="outGoods" v-if="user.roleId!=2">出库</el-button> -->
        </div>
        <el-table :data="tableData"
                  :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
                  border
                  highlight-current-row
                  @current-change="selectCurrentChange"
        >
            <el-table-column prop="id" label="ID" width="60">
            </el-table-column>
            <el-table-column prop="name" label="产品名" width="250">
            </el-table-column>
            <el-table-column prop="currentPurchasePrice" label="采购价" width="160">
            </el-table-column>
            <el-table-column prop="currentSellingPrice" label="销售价" width="160">
            </el-table-column>
            <el-table-column prop="currentGrossProfit" label="销售利润" width="160">
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160">
            </el-table-column>
            <el-table-column prop="lastUpdateTime" label="修改时间" width="160">
            </el-table-column>
            <el-table-column prop="operate" label="操作" v-if="user.roleId!=2">
                <template slot-scope="scope">
                    <el-button size="small" type="success" @click="mod(scope.row)">编辑</el-button>
                    <el-popconfirm
                            title="确定删除吗？"
                            @confirm="del(scope.row.id)"
                            style="margin-left: 5px;"
                    >
                        <el-button slot="reference" size="small" type="danger" >删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[5, 10, 20,30]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
        <el-dialog
                  title="价格维护"
                  :visible.sync="centerDialogVisible"
                  width="30%"
                  center>
  
              <el-form ref="form" :rules="rules" :model="form" label-width="80px">
                <el-form-item label="ID" prop="id">
                      <el-col :span="20">
                          <el-input readonly v-model="form.id"></el-input>
                      </el-col>
                  </el-form-item>
                 <el-form-item label="产品名" prop="name">
                      <el-col :span="20">
                          <el-input readonly v-model="form.name"></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="采购价" prop="currentPurchasePrice">
                      <el-col :span="20">
                          <el-input readonly v-model="form.currentPurchasePrice"></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="利润" prop="currentGrossProfit">
                      <el-col :span="20">
                          <el-input readonly v-model="form.currentGrossProfit"></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="售价" prop="currentSellingPrice">
                      <el-col :span="20">
                          <el-input v-model="form.currentSellingPrice"></el-input>
                      </el-col>
                  </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </span>
        </el-dialog>
        <el-dialog
                  title="新增产品价格"
                  :visible.sync="newPriceDialogVisible"
                  width="30%"
                  center>
  
              <el-form ref="newPriceform" :rules="rules" :model="newPriceform" label-width="80px">
                 <el-form-item label="产品名" prop="name">
                    <el-col :span="20">
                        <el-select filterable clearable v-model="newPriceform.id" @change="changeSkuInfo" placeholder="请选择产品" style="margin-left: 5px;">
                            <el-option
                                    v-for="item in noPriceSkuDataList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-col>
                  </el-form-item>
                  <el-form-item label="采购价" prop="currentPurchasePrice">
                      <el-col :span="20">
                          <el-input readonly v-model="newPriceform.currentPurchasePrice"></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="利润" prop="currentGrossProfit">
                      <el-col :span="20">
                          <el-input readonly v-model="newPriceform.currentGrossProfit" @blur="handleInputBlur"></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="售价" prop="currentSellingPrice">
                      <el-col :span="20">
                          <el-input @change="changeSellingPrice" v-model="newPriceform.currentSellingPrice"></el-input>
                      </el-col>
                  </el-form-item>
              </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="newPriceDialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="newPriceSave">确 定</el-button>
                </span>
        </el-dialog>
          <el-dialog
                  title="出入库"
                  :visible.sync="inDialogVisible"
                  width="30%"
                  center>
  
              <el-dialog
                      width="75%"
                      title="用户选择"
                      :visible.sync="innerVisible"
                      append-to-body>
                  <SelectUser @doSelectUser="doSelectUser"></SelectUser>
                  <span slot="footer" class="dialog-footer">
                      <el-button @click="innerVisible = false">取 消</el-button>
                      <el-button type="primary" @click="confirmUser">确 定</el-button>
                    </span>
              </el-dialog>
  
              <el-form ref="form1" :rules="rules1" :model="form1" label-width="80px">
                  <el-form-item label="物品名">
                      <el-col :span="20">
                          <el-input v-model="form1.goodsname" readonly></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="申请人">
                      <el-col :span="20">
                          <el-input v-model="form1.username" readonly @click.native="selectUser"></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="数量" prop="count">
                      <el-col :span="20">
                          <el-input v-model="form1.count"></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="备注" prop="remark">
                      <el-col :span="20">
                          <el-input type="textarea" v-model="form1.remark"></el-input>
                      </el-col>
                  </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
      <el-button @click="inDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="inDialogVisible = false">确 定</el-button>
    </span>
          </el-dialog>
    </div>
  </template>
  
  
  <script>
    export default {
        name: "SellingPriceManage",
        data() {
            let checkCount = (rule, value, callback) => {
                if(value>9999){
                    callback(new Error('数量输入过大'));
                }else{
                    callback();
                }
            };
  
            return {
                user : JSON.parse(sessionStorage.getItem('CurUser')),
                storageData:[],
                noPriceSkuDataList:[],
                goodstypeData:[],
                tableData: [],
                pageSize:10,
                pageNum:1,
                total:0,
                name:'',
                storage:'',
                goodstype:'',
                centerDialogVisible:false,
                newPriceDialogVisible:false,
                inDialogVisible:false,
                innerVisible:false,
                currentRow:{},
                tempUser:{},
                form:{
                    id:'',
                    currentSellingPrice:0,
                },
                form1:{
                    goods:'',
                    goodsname:'',
                    count:'',
                    username:'',
                    userid:'',
                    adminId:'',
                    remark:'',
                    action:'1'
                },
                newPriceform:{
                    id:'',
                    currentSellingPrice: 0.0,
                    currentGrossProfit: 0.0,
                },
                rules1: {
  
                },
                rules: {
                    currentSellingPrice: [
                        {required: true, message: '请输入价格', trigger: 'blur'},
                        {pattern: /^(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*))$/,message: '数量必须为正整数字,或者小数',trigger: "blur"},
                        {validator:checkCount,trigger: 'blur'}
                    ],
                }
            }
        },
        methods:{
            doSelectUser(val){
                console.log(val)
                this.tempUser = val
            },
            confirmUser(){
                this.form1.username = this.tempUser.name
                this.form1.userid = this.tempUser.id
  
                this.innerVisible = false
            },
            selectCurrentChange(val) {
                this.currentRow = val;
            },
            formatStorage(row){
                let temp =  this.storageData.find(item=>{
                    return item.id == row.storage
                })
  
                return temp && temp.name
            },
            formatGoodstype(row){
                let temp =  this.goodstypeData.find(item=>{
                    return item.id == row.goodstype
                })
  
                return temp && temp.name
            },
            resetForm() {
                this.$refs.form.resetFields();
            },
            resetNewPriceform() {
                this.$refs.newPriceform.resetFields();
            },
            resetInForm(){
                this.$refs.form1.resetFields();
            },
            del(id){
                console.log(id)
  
                this.$axios.get(this.$httpUrl+'/sellingPrice/del?id='+id).then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
  
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.loadPost()
                    }else{
                        this.$message({
                            message: '操作失败！',
                            type: 'error'
                        });
                    }
  
                })
            },
            mod(row){
                this.centerDialogVisible = true
                this.$nextTick(()=>{
                    //赋值到表单
                    this.form.id = row.id
                    this.form.name = row.name
                    this.form.currentPurchasePrice = row.currentPurchasePrice
                    this.form.currentGrossProfit = row.currentGrossProfit
                    this.form.currentSellingPrice = row.currentSellingPrice
                })
            },
            add(){
  
                this.centerDialogVisible = true
                this.$nextTick(()=>{
                    this.resetForm()
                    this.form.id = ''
                })
            },
            addNewPrice(){
                this.newPriceDialogVisible = true
                this.loadNoPriceSkuDataList()
                this.$nextTick(()=>{
                    this.resetNewPriceform()
                })
            },
            inGoods(){
                if(!this.currentRow.id){
                    alert('请选择记录');
                    return;
                }
                this.inDialogVisible = true
                this.$nextTick(()=>{
                    this.resetInForm()
                })
  
                this.form1.goodsname = this.currentRow.name
                this.form1.goods = this.currentRow.id
                this.form1.adminId=this.user.id
                this.form1.action='1'
            },
            outGoods(){
                if(!this.currentRow.id){
                    alert('请选择记录');
                    return;
                }
                this.inDialogVisible = true
                this.$nextTick(()=>{
                    this.resetInForm()
                })
  
                this.form1.goodsname = this.currentRow.name
                this.form1.goods = this.currentRow.id
                this.form1.adminId=this.user.id
                this.form1.action='2'
  
            },
            selectUser(){
                this.innerVisible=true
            },
            doSave(){
                this.$axios.post(this.$httpUrl+'/sellingPrice/save',this.form).then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.centerDialogVisible = false
                        this.loadPost()
                        this.resetForm()
                    }else{
                        this.$message({
                            message: '操作失败！',
                            type: 'error'
                        });
                    }
  
                })
            },
            doMod(){
                this.$axios.post(this.$httpUrl+'/sellingPrice/update',{...this.form,
                    currentPurchasePrice:parseInt(this.form.currentPurchasePrice * 100),
                    currentSellingPrice:parseInt(this.form.currentSellingPrice * 100),
                }).then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        this.centerDialogVisible = false
                        this.loadPost()
                        this.resetForm()
                    }else{
                        this.$message({
                            message: '操作失败！',
                            type: 'error'
                        });
                    }
  
                })
            },
            save(){
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        if(this.form.id){
                            this.doMod();
                        }else{
                            this.doSave();
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            newPriceSave(){
                this.newPriceDialogVisible = false
                this.$axios.post(this.$httpUrl + '/sellingPrice/save', { ...this.newPriceform, 
                    currentPurchasePrice:parseInt(this.newPriceform.currentPurchasePrice * 100),
                    currentSellingPrice:parseInt(this.newPriceform.currentSellingPrice * 100),
                    currentGrossProfit:parseInt(this.newPriceform.currentGrossProfit * 100),
                }).then(res => res.data).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        this.$message({
                            message: '操作成功！',
                            type: 'success'
                        });
                        // this.centerDialogVisible = false
                        this.loadPost()
                        this.$refs.newPriceform.resetFields();
                    } else {
                        this.$message({
                            message: '操作失败！',
                            type: 'error'
                        });
                    }
                })
            },
            // doInGoods(){
            //     this.$axios.post(this.$httpUrl+'/record/save',this.form1).then(res=>res.data).then(res=>{
            //         console.log(res)
            //         if(res.code==200){
  
            //             this.$message({
            //                 message: '操作成功！',
            //                 type: 'success'
            //             });
            //             this.inDialogVisible = false
            //             this.loadPost()
            //             this. resetInForm()
            //         }else{
            //             this.$message({
            //                 message: '操作失败！',
            //                 type: 'error'
            //             });
            //         }
  
            //     })
            // },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageNum=1
                this.pageSize=val
                this.loadPost()
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.pageNum=val
                this.loadPost()
            },
            resetParam(){
                this.name=''
                this.storage=''
                this.goodstype=''
            },
            loadPost(){
                this.$axios.post(this.$httpUrl+'/sellingPrice/listPage',{
                    pageSize:this.pageSize,
                    pageNum:this.pageNum,
                    param:{
                        name:this.name,
                    }
                }).then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.tableData=res.data.map(item => {
                            return {
                                ...item,
                                currentPurchasePrice:(item.currentPurchasePrice * 1.0 / 100).toFixed(2),
                                currentSellingPrice:(item.currentSellingPrice * 1.0 / 100).toFixed(2),
                                currentGrossProfit:(item.currentGrossProfit * 1.0 / 100).toFixed(2),
                            }
                        })
                        this.total=res.total
                    } else {
                        alert('获取数据失败')
                    }
  
                })
            },
            // loadStorage(){
            //     this.$axios.get(this.$httpUrl+'/storage/list').then(res=>res.data).then(res=>{
            //         console.log(res)
            //         if(res.code==200){
            //             this.storageData=res.data
            //         }else{
            //             alert('获取数据失败')
            //         }
  
            //     })
            // },
            loadStorage(){
                this.$axios.get(this.$httpUrl+'/storage/list').then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.storageData=res.data
                    }else{
                        alert('获取数据失败')
                    }
  
                })
            },
            loadNoPriceSkuDataList(){
                this.$axios.get(this.$httpUrl+'/sku/noPriceSkulist').then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.noPriceSkuDataList=res.data.map(item => {
                            return {
                                ...item,
                                price: (item.price * 1.0 / 100).toFixed(2),
                            }
                        })
                    } else {
                        alert('获取数据失败')
                    }
  
                })
            },
            loadGoodstype(){
                this.$axios.get(this.$httpUrl+'/goodstype/list').then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.goodstypeData=res.data
                    }else{
                        alert('获取数据失败')
                    }
  
                })
            },
            changeSkuInfo(val){
                console.log("--------   changeSkuInfo  ------")
                console.log(val)
                let tempSkuinfo = this.noPriceSkuDataList.find(item => {
                    return item.id == val
                })
                console.log(JSON.stringify(tempSkuinfo))
                this.newPriceform.currentPurchasePrice = tempSkuinfo.price
                this.newPriceform.name = tempSkuinfo.name
                this.newPriceform.skuId = tempSkuinfo.id
                // this.$nextTick(() => {
                // //赋值到表单
                // // this.newPriceform.id = row.id
                // this.newPriceform.currentPurchasePrice = tempSkuinfo.price
                // // this.newPriceform.name = row.name
                // // this.newPriceform.imagesPath = row.imagesPath
                // // this.newPriceform.color = row.color
                // })
            },
            changeSellingPrice(val){
                console.log("--------   changeSellingPrice  ------")
                console.log(val)
                console.log("newPriceform.currentSellingPrice = " + this.newPriceform.currentSellingPrice)
                console.log("newPriceform.currentPurchasePrice = " + this.newPriceform.currentPurchasePrice)
                this.newPriceform.currentGrossProfit = this.newPriceform.currentSellingPrice - this.newPriceform.currentPurchasePrice
                this.$forceUpdate()  //这个方法可以
                // this.inputElement.dispatchEvent(new Event("input"));
                // this.$set(this.newPriceform,'currentGrossProfit',this.newPriceform.currentSellingPrice - this.newPriceform.currentPurchasePrice)
            },
            handleInputBlur(e) {
                console.log("--------   handleInputBlur  ------")
                console.log(JSON.stringify(e))
                // this.inputElement = e.srcElement;
            }
        },
        beforeMount() {
            this.loadStorage()
            this.loadGoodstype()
            this.loadPost()
            this.loadNoPriceSkuDataList()
        }
    }
  </script>
  
  <style scoped>
  
  </style>